<template>
	<photo img="/img/e9/pb/audience" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: '',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: 'E9.PB.Gestion',
						name: 'Gestión',
						left: 21.2, //'630px',
						top: 40.3, //'300px',
						width: 3.6, //'125px',
						height: 18.7 //'160px'
					},
                    {
						link: 'E9.PB.Direction',
						name: 'Gobierno',
						left: 67.1, //'2150px',
						top: 41.5, //'300px',
						width: 5.6, //'125px',
						height: 14.7 //'160px'
					},
				]
			}
		}
	}
</script>
