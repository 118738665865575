<template>
	<photo img="/img/e9/pb/gestion" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: 'Gestion',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: null,
						name: 'Gestión escolar',
                        desc: `En el Departamento de Gestión Escolar se realizan semestralmente los trámites de:
                        <ul style="text-align: left;">
                            <li> Inscripción </li>
                            <li> Reinscripción </li>
                            <li> Bajas </li>
                            <li> Emisión de constancias </li>
                            <li> Boletas (globales y certificadas) </li>
                            <li> Cambios de Unidad Académica y/o de programa de estudios </li>
                        </ul>    
                        Entre otros.<br>
                        El horario de atención es de 9:00 hrs a 20:00 hrs con un periodo de descanzo de 14:00 a 15:30 hrs.<br>
                        La persona encargada del área es: ... <br>
                        Támbien se puede contactar con este departamento para cualquier duda o sugerencia a traves del correo electronico:<br>
                        <p style="font-weight:800;text-align:center;">correo@ipn.mx</p>`,
						left: 58, //'820px',
                        top: 35, //'320px',
						width: 8, //'125px',
						height: 40,
                        p: 200,
                        r: 20
					},
                    {
						link: 'E9.PB.Services',
						name: 'Servicios',
						left: 22.3, //'820px',
                        top: 39.5, //'320px',
						width: 4, //'125px',
						height: 17.7 //'160px'
					},
                    {
						link: 'E9.PB.Audience',
						name: 'Auditorio',
						left: 72, //'2620px',
						top: 39.5, //'320px',
						width: 4, //'125px',
						height: 18.7 //'160px'
					},
				]
			}
		}
	}
</script>
