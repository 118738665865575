<template>
	<photo img="/img/e9/pb/services" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: '',
		components: {
			photo
		},
		data (){
			return {
				points: [
					{
						link: 'Home',
						name: 'Frontal',
						left: 22.3, //'820px',
						top: 41, //'340px',
						width: 2.8, //'120px',
						height: 11.1 //'100px'
					},
                    {
						link: null,
						name: 'Servicios',
                        desc: `
                        Los servicios que la Escuela Superior de Fisica y Matemáticas ofrece a todos sus estudiantes son:
                        <ol>
                            <li> Atención medica
                                <ul>
                                    <li> Médico cirujano y homeopata </li>
                                    <li> Enfermería y obstetricia </li>
                                    <li> Médico cirujano y partero </li>
                                </ul>
                            </li>
                            <li> Atención dental
                                <ul> 
                                    <li> Lic. Cirujano dentista </li>
                                </ul>
                            </li>
                            <li> Atención psicologica
                                <ul> 
                                    <li> Dra. en Psicología clinica </li>
                                </ul>
                            </li>
                            <li> Atención nutricional
                                <ul> 
                                    <li> Licenciado en nutrición </li>
                                </ul>
                            </li>
                        </ol>
                        Esto con la intención de preservar la salud y bienestar de nuestros estudiantes, mediante el desarrollo e implementación de estrategias de prevención de enfermedades y conservación de la salud.`,
						left: 23.8, //'820px',
						top: 42, //'340px',
						width: 5, //'120px',
						height: 16, //'100px'
                        p: 250,
                        r: -65
					},
                    {
						link: 'E9.PB.Gestion',
						name: 'Gestión',
						left: 71.2, //'2660px',
						top: 31.5, //'300px',
						width: 5.5, //'125px',
						height: 30 //'160px'
					},
                    {
						link: 'E9.P1.21',
						name: 'Siglo XXI',
						left: 91, //'3450px',
						top: 18.8, //'170px',
						width: 8, //'200px',
						height: 54 //'180px'
					},
				]
			}
		}
	}
</script>
