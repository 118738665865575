<template>
	<photo img="/img/e9/frontal" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: '',
		components: {
			photo
		},
		data (){
			return {
				points: [
					{
						link: 'E9.PB.Services',
						name: 'Edificio 9',
						left: 66, //1950,
						top: 50, //400,
						width: 10, //280,
						height: 40 //320
					},
					{
						link: 'Home',
						name: 'Biblioteca',
						left: 16.5, //520,
						top: 64, //500,
						width: 4.4, //150,
						height: 13.3 //120
					},
				]
			}
		}
	}
</script>
