<template>
	<photo img="/img/e9/pb/direction" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: '',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: null,
						name: 'Gobierno',
                        desc: 'Área de gobierno',
						left: 63, //'930px',
						top: 37, //'330px',
						width: 8.7, //'330px',
						height: 27, //'160px'
                        p: 400,
                        r: 45
					},
                    {
						link: 'E9.PB.Audience',
						name: 'Auditorio',
						left: 23, //'930px',
						top: 40.6, //'330px',
						width: 8.7, //'330px',
						height: 19.7 //'160px'
					},
                    {
						link: 'E9.Posterior',
						name: 'Estacionamiento',
						left: 71, //'2750px',
						top: 41, //'330px',
						width: 5.7, //'200px',
						height: 16.7 //'160px'
					},
                    {
						link: 'E9.P1.Prefecture',
						name: 'Planta 1',
						left: 91, //'3500px',
						top: 28, //'330px',
						width: 10, //'230px',
						height: 49 //'160px'
					},
				]
			}
		}
	}
</script>
