<template>
	<photo img="/img/e9/posterior" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: '',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: 'E9.PB.Direction',
						name: 'Edificio 9',
						left: '1020px',
						top: '310px',
						width: '125px',
						height: '110px'
					},
				]
			}
		}
	}
</script>
